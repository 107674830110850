import React, { useState, useContext } from "react";
import { CButton, CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle } from "@coreui/react";

export const AlertContext = React.createContext({
    active: false,
    title: "No title",
    text: "No text",
    static: false,
    cb: () => { },
    AddAlert: (title, text, staticBackdrop, callback) => { },
    RemoveAlert: () => { }
})

export function AlertUI() {
    const { alert, RemoveAlert } = useAlertContext();

    const handleRemoveAlert = (ev) => {
        ev.preventDefault();
        if (alert.static == false) {
            RemoveAlert();
        }
    }

    return (
        <>
            {alert && <>
                <CModal className={"d-flex align-items-center pb-5"} closeOnBackdrop={alert.static} show={alert.active} onClose={RemoveAlert} backdrop={alert.backdrop}>
                    <CModalHeader>
                        <CModalTitle>{alert.title}</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        {alert.text}
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={RemoveAlert}>
                            Annuller
                        </CButton>
                        <CButton color="primary"
                            onClick={() => {
                                alert.cb();
                                RemoveAlert();
                            }}
                        >Ja, jeg er sikker</CButton>
                    </CModalFooter>
                </CModal>
            </>}
        </>
    )
}

export const useAlertContext = () => {
    return useContext(AlertContext);
}

export function AlertConfirmProvider({ children }) {
    const [alert, setAlert] = useState(null);

    const SetAlertControl = (title, text, backdrop, callback) => {
        setAlert({
            active: true,
            title: title,
            text: text,
            backdrop: backdrop,
            cb: callback
        })
    }
    const removeAlert = () => setAlert(null);

    const contextValue = {
        alert,
        AddAlert: (title, text, backdrop, callback) => SetAlertControl(title, text, backdrop, callback),
        RemoveAlert: () => removeAlert()
    }

    return (
        <AlertContext.Provider value={contextValue}>
            {children}
        </AlertContext.Provider>
    )
}