import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
    cibSkype,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibGmail,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibMathworks,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex,
} from "@coreui/icons";
import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from "@coreui/icons";
import {
    cilAlignCenter,
    cilAlignLeft,
    cilCheckAlt,
    cilAlignRight,
    cilImage,
    cilApplicationsSettings,
    cilArrowRight,
    cilArrowTop,
    cilAsterisk,
    cilBan,
    cilBasket,
    cilCopy,
    cilCart,
    cilBell,
    cilArrowThickToRight,
    cilBarcode,
    cilBold,
    cilBookmark,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilFullscreen,
    cilFullscreenExit,
    cilGraph,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilCommentBubble,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPrint,
    cilClock,
    cilPuzzle,
    cilSave,
    cilSearch,
    cilScrubber,
    cilSettings,
    cilArrowThickBottom,
    cilShare,
    cilCloudUpload,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cibLanyrd,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilGift,
    cilUserFollow,
    cilApps,
    cilUserUnfollow,
    cilX,
    cilXCircle,
    cilAddressBook,
    cilWarning,
} from "@coreui/icons";

export const icons = Object.assign(
    {},
    {
        sygnet,
        logo,
        logoNegative,
    },
    {
        cilAlignCenter,
        cilAlignLeft,
        cilAlignRight,
        cilApplicationsSettings,
        cilCart,
        cilArrowRight,
        cilArrowTop,
        cilAsterisk,
        cilBan,
        cilAddressBook,
        cilNotes,
        cilCopy,
        cilApps,
        cilCheckAlt,
        cibGmail,
        cibMathworks,
        cilBarcode,
        cilGift,
        cilBasket,
        cilArrowThickToRight,
        cilBell,
        cilBold,
        cilCloudUpload,
        cilCommentBubble,
        cibLanyrd,
        cilSearch,
        cilClock,
        cilBookmark,
        cilArrowThickBottom,
        cilCalculator,
        cilCalendar,
        cilCloudDownload,
        cilChartPie,
        cilCheck,
        cilChevronBottom,
        cilChevronLeft,
        cilChevronRight,
        cilChevronTop,
        cilCircle,
        cilCheckCircle,
        cilCode,
        cilCommentSquare,
        cilCreditCard,
        cilCursor,
        cilCursorMove,
        cilImage,
        cilDrop,
        cilDollar,
        cilEnvelopeClosed,
        cilEnvelopeLetter,
        cilEnvelopeOpen,
        cilEuro,
        cilGlobeAlt,
        cilGrid,
        cilFile,
        cilFullscreen,
        cilFullscreenExit,
        cilGraph,
        cilHome,
        cilInbox,
        cilIndentDecrease,
        cilIndentIncrease,
        cilInputPower,
        cilItalic,
        cilJustifyCenter,
        cilJustifyLeft,
        cilLaptop,
        cilLayers,
        cilLightbulb,
        cilList,
        cilListNumbered,
        cilListRich,
        cilLocationPin,
        cilLockLocked,
        cilMagnifyingGlass,
        cilMap,
        cilMoon,
        cilNotes,
        cilOptions,
        cilPaperclip,
        cilPaperPlane,
        cilPencil,
        cilPeople,
        cilPhone,
        cilPrint,
        cilPuzzle,
        cilSave,
        cilScrubber,
        cilSettings,
        cilShare,
        cilShareAll,
        cilShareBoxed,
        cilShieldAlt,
        cilSpeech,
        cilSpeedometer,
        cilSpreadsheet,
        cilStar,
        cilSun,
        cilTags,
        cilTask,
        cilTrash,
        cilUnderline,
        cilUser,
        cilUserFemale,
        cilUserFollow,
        cilUserUnfollow,
        cilX,
        cilXCircle,
        cilWarning,
    },
    {
        cifUs,
        cifBr,
        cifIn,
        cifFr,
        cifEs,
        cifPl,
    },
    {
        cibSkype,
        cibFacebook,
        cibTwitter,
        cibLinkedin,
        cibFlickr,
        cibTumblr,
        cibXing,
        cibGithub,
        cibStackoverflow,
        cibYoutube,
        cibDribbble,
        cibInstagram,
        cibPinterest,
        cibVk,
        cibYahoo,
        cibBehance,
        cibReddit,
        cibVimeo,
        cibCcMastercard,
        cibCcVisa,
        cibStripe,
        cibPaypal,
        cibGooglePay,
        cibCcAmex,
    }
);
