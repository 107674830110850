import { createStore } from 'redux'

const initialState = {
	sidebarShow: 'responsive',
	user: () => {
		if (localStorage.getItem('token') !== null) {
			return JSON.parse(localStorage.getItem('token'))
		} else {
			return { loggedIn: false, permissions: {}, username: '', email: '', usertype: '' }
		}
	}
}

const changeState = (state = initialState, action) => {
	switch (action.type) {
		case 'sidebar':
			return { ...state, sidebarShow: action.payload }
		case 'login':
			localStorage.setItem('token', JSON.stringify(action.payload));
			return { ...state, user: () => action.payload }
		case 'logout':
			localStorage.setItem('token', JSON.stringify({ loggedIn: false, permissions: {}, username: '', email: '' }));
			return { ...state, user: () => { return { loggedIn: false, permissions: {}, username: '', email: '' } } }
		default:
			return state
	}
}

const store = createStore(changeState)
export default store